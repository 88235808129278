import React from "react";
import Dropdown from "rsuite/Dropdown";
import "rsuite/dist/rsuite.min.css";

import { Image } from "./image";
// const logo = require('../public/img/logo.png'); // with require

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <div className="col-sm-1   col-lg-2">
            {/* <Image smallImage="img/logo.png" width={30} height={30} /> */}

            <img src="img/logo.png" width={65} height={50} />
          </div>

          <a className="navbar-brand page-scroll">Vincenzo Technology</a>
        </div>
        {/* <div className="col-sm-1   col-lg-2">
          <a className="navbar-brand page-scroll" href="#page-top">
            Vincenzo Technology
          </a>
        </div> */}
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>

            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Products
              </a>

              {/* <Dropdown title="Products" className="page-scroll">
                <Dropdown.Item>Item 1</Dropdown.Item>
                <Dropdown.Menu title="Item 2">
                  <Dropdown.Item>Item 2A</Dropdown.Item>
                  <Dropdown.Item>Item 2B</Dropdown.Item>
                </Dropdown.Menu>
                <Dropdown.Item>Item 3</Dropdown.Item>
                <Dropdown.Menu title="Item 4">
                  <Dropdown.Menu title="Item 4A">
                    <Dropdown.Item>Item 4A-A</Dropdown.Item>
                    <Dropdown.Item>Item 4A-B</Dropdown.Item>
                  </Dropdown.Menu>
                  <Dropdown.Item>Item 4B</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            {/* <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li> */}
            <li>
              <a href="#team" className="page-scroll">
                ACHIEVEMENT
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
